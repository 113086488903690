import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Actions
import {hide as hideAct} from '../../redux/actions';

// Components
import {Portal} from '@matthahn/sally-ui';
import SpotlightWrapper from '../../components/SpotlightWrapper/SpotlightWrapper';
import Spotlight from '../../components/Spotlight/Spotlight';

// Containers
import DriversSpotlightSync from '../../../driver/containers/DriversSpotlightSync/DriversSpotlightSync';
import MedallionsSpotlightSync from '../../../medallion/containers/MedallionsSpotlightSync/MedallionsSpotlightSync';
import VehiclesSpotlightSync from '../../../vehicle/containers/VehiclesSpotlightSync/VehiclesSpotlightSync';

// Routes
import driverRoute from '../../../driver/pages/DriverPage/route';
import medallionRoute from '../../../medallion/pages/MedallionPage/route';
import vehicleRoute from '../../../vehicle/pages/VehiclePage/route';

// Lib
import wait from '../../../lib/wait';
import fkOrId from '../../../lib/fkOrId';

// types
import phoneNumberType from '../../../types/types/phoneNumber.type';

class SpotlightContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    drivers: PropTypes.array,
    medallions: PropTypes.array,
    vehicles: PropTypes.array,
    dispatch: PropTypes.func,
    history: PropTypes.object,
  };

  state = {
    search: '',
    showResults: false,
    drivers: [],
    medallions: [],
    vehicles: [],
  };

  onEscape = () => {
    if (!!this.state.search)
      return this.setState({search: '', showResults: false});
    this.onClose();
  };

  onClose = () => {
    if (!this.props.visible) return;
    this.props.dispatch(hideAct());
  };

  onSearch = (search) => {
    if (!this.props.visible) return;
    this.setState({search}, () => this.searchForData(search));
  };

  searchForData = async (search) => {
    await wait(300);
    if (this.state.search !== search) return;
    if (search.trim().length < 3) return this.setState({showResults: false});
    this.setState({showResults: true});
  };

  drivers = () => {
    const {drivers} = this.props;
    const {search: rawSearch} = this.state;
    const search = rawSearch.toLowerCase();
    return search.trim().length < 3
      ? []
      : [...drivers]
          .filter(
            ({
              id,
              first_name,
              last_name,
              phone_number,
              email,
              fhv_license_number,
              ssn,
            }) =>
              [
                `${last_name}_${first_name}_${id}`,
                `${first_name} ${last_name}`,
                phone_number,
                `+1${phone_number}`,
                `${phoneNumberType(phone_number).format()}`,
                email,
                fhv_license_number,
                ssn,
              ].some(
                (attr) => !!attr && `${attr}`.toLowerCase().includes(search)
              )
          )
          .sort((a, b) => {
            const nameA = `${a.first_name} ${a.last_name}`;
            const nameB = `${b.first_name} ${b.last_name}`;
            if (nameA > nameB) return 1;
            if (nameA < nameB) return -1;
            return 0;
          })
          .slice(0, 10);
  };

  medallions = () => {
    const {medallions} = this.props;
    const {search: rawSearch} = this.state;
    const search = rawSearch.toLowerCase();
    return search.trim().length < 3
      ? []
      : [...medallions]
          .filter(({medallion_number}) =>
            `${medallion_number}`.toLowerCase().includes(search)
          )
          .sort((a, b) => {
            if (a.medallion_number > b.medallion_number) return 1;
            if (a.medallion_number < b.medallion_number) return -1;
            return 0;
          })
          .slice(0, 10);
  };

  vehicles = () => {
    const {vehicles} = this.props;
    const {search: rawSearch} = this.state;
    const search = rawSearch.toLowerCase();
    return search.trim().length < 3
      ? []
      : [...vehicles]
          .filter(({svid, vin, plate}) =>
            [svid, vin, plate].some(
              (attr) => !!attr && `${attr}`.toLowerCase().includes(search)
            )
          )
          .sort((a, b) => {
            if (a.vin > b.vin) return 1;
            if (a.vin < b.vin) return -1;
            return 0;
          })
          .slice(0, 10);
  };

  clearAndClose = () => {
    this.setState({search: '', showResults: false});
    this.onClose();
  };

  driverClick = (driver) => () => {
    this.clearAndClose();
    this.props.history.push(driverRoute(driver.id));
  };

  medallionClick = (medallion) => () => {
    const {vehicles} = this.props;
    this.clearAndClose();
    const vehicle = [...vehicles].find(
      (vehicle) => fkOrId(vehicle.medallion) === medallion.id
    );
    this.props.history.push(
      !!vehicle ? vehicleRoute(vehicle.id) : medallionRoute(medallion.id)
    );
  };

  vehicleClick = (vehicle) => () => {
    this.clearAndClose();
    this.props.history.push(vehicleRoute(vehicle.id));
  };

  render() {
    const {visible} = this.props;
    const {search, showResults} = this.state;
    return (
      <Fragment>
        <Portal always>
          <SpotlightWrapper visible={visible}>
            <Spotlight
              showResults={showResults}
              visible={visible}
              search={search}
              drivers={this.drivers()}
              medallions={this.medallions()}
              vehicles={this.vehicles()}
              onSearch={this.onSearch}
              onClose={this.onClose}
              onEscape={this.onEscape}
              onDriver={this.driverClick}
              onMedallion={this.medallionClick}
              onVehicle={this.vehicleClick}
            />
          </SpotlightWrapper>
        </Portal>
        <DriversSpotlightSync />
        <MedallionsSpotlightSync />
        <VehiclesSpotlightSync />
      </Fragment>
    );
  }
}

export default withRouter(
  connect((state) => ({...state.spotlight}))(SpotlightContainer)
);
